
import Vue from 'vue'
import pdf from '@teckel/vue-pdf'
import { mapGetters } from 'vuex'
import { FButton } from 'fwkc4-vue'
import { fechaHoraPdf } from '@/utils/utils'
import DocumentData from './DocumentData.vue'
import getTransactionSgaimUrl from '@/services/transactions/getTransactionSgaimUrl.management'
import downloadDocument from '@/services/transactions/downloadDocument.management'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import env from '@/services/environment'

export default Vue.extend({
  name: 'Documento',
  components: {
    DocumentData,
    FButton,
    pdf
  },
  props: {
    showButtons: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      url: null,
      dataSrc: null,
      curDoc: null,
      urlDisponible: true,
      isPDFSrc: false,
      page: 1,
      numPages: 0,
      errors: [],
      documentKO: false
    }
  },
  computed: {
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    ...mapGetters('user', [
      'isUsuarioInterno',
      'getUsername'
    ]),
    getContractUrl () {
      return this.url
    },
    texareaClass () {
      return this.showButtons ? 'buttons' : 'no-buttons'
    }
  },
  mounted () {
    this.bindData()
    this.sgaimHookPM()
  },
  methods: {
    cancelChanges () {
      this.$emit('onCancelChanges')
    },
    confirmSaveChanges () {
      this.$emit('onConfirmSaveChanges')
    },
    async bindData () {
      this.transaction = this.getCurrentTransaction
      this.urlDisponible = this.transaction.url_disponible
      if (this.urlDisponible === true && this.transaction?.estado?.estadoInterno === 'PENDIENTE') {
        this.isPDFSrc = false
        this.loadUrl()
      } else {
        this.isPDFSrc = true
        this.loadPDF()
      }
    },
    async loadUrl () {
      let response = null
      try {
        // this.$store.dispatch('ui/showMask')
        const searchParams = {
          id: this.transaction.id
        }
        response = await getTransactionSgaimUrl(searchParams)
        if (response?.url?.length > 0) {
          this.url = response.url
          if (this.isUsuarioInterno === true) {
            this.url += '&auth_link_intelectual=' + this.getUsername.toUpperCase()
          }
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    async loadPDF () {
      let response: any = null
      this.curDoc = null
      const documents = this.transaction.documents
      let docId = 0
      documents.forEach((document) => {
        if (document.principal === true) {
          docId = document.id
          this.curDoc = document
        }
      })
      // si no  hay principal, coge el primero sea el que sea
      if (docId === 0) {
        docId = documents[0].id
        this.curDoc = documents[0]
      }
      // descarga
      try {
        const params = {
          transaccion_id: this.transaction.id,
          documento_id: docId,
          history: (this.transaction.historico === true) ? 'yes' : null,
          year: this.transaction.anyo
        }
        response = await downloadDocument(params)
        if (response?.stream) {
          this.documentKO = false
          // this.dataSrc = response.stream
          // this.dataSrc = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
          this.dataSrc = window.URL.createObjectURL(response.stream)
          const pdfsrc = pdf.createLoadingTask(this.dataSrc)
          pdfsrc.promise
            .then(pdf => {
              this.numPages = pdf.numPages
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.$store.dispatch('ui/openDialog', {
            component: GenericErrorDialog,
            props: {
              text: 'La descarga del documento ha fallado',
              secondModal: true
            }
          })
        }
      } catch (err) {
        console.log(err)
        if (err.response.status === 500 || err.response.status === 502) {
          this.$store.dispatch('ui/hideMask')
          this.documentKO = true
          this.isPDFSrc = false
        } else {
          this.$store.dispatch('ui/openDialog', {
            component: GenericErrorDialog,
            props: {
              text: 'Se ha producido un error, inténtalo de nuevo más tarde'
            }
          })
        }
      }
    },
    async onDownloadPdf () {
      let response: any = null
      try {
        this.$store.dispatch('ui/showMask')
        const params = {
          transaccion_id: this.transaction.id,
          documento_id: this.curDoc.id,
          history: (this.transaction.historico === true) ? 'yes' : null,
          year: this.transaction.anyo
        }
        response = await downloadDocument(params)
        if (response?.stream) {
          const name = ('Documento-').concat(this.transaction.id).concat('-').concat(fechaHoraPdf()).concat('.pdf')
          this.exportToFile(response, name)
        } else {
          this.$store.dispatch('ui/openDialog', {
            component: GenericErrorDialog,
            props: {
              text: 'La descarga del fichero ha fallado',
              secondModal: true
            }
          })
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    async exportToFile (data, name) {
      try {
        if (data.mimeType === 'application/pdf') {
          const objectURL = window.URL.createObjectURL(data.stream)
          const anchor = document.createElement('a')
          anchor.href = objectURL
          anchor.download = name
          anchor.click()
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    sgaimHookPM () {
      if (window.addEventListener) {
        window.addEventListener('message', this.onMessage, false)
      }
    },
    onMessage (event) {
      const sgaimBaseUrl = env.getEnvVariable('VUE_APP_SGAIM_BASE_URL')
      if (event.origin !== sgaimBaseUrl) return
      const data = event.data
      if (data.actionType === 'EIS01ReceiveMessage') {
        const event = new CustomEvent('EIS01ReceiveMessage', {
          detail: {
            result: data.message
          }
        })
        document.dispatchEvent(event)
      }
    }
  }
})
